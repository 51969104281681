// AppNavigator.js
import React, {useContext, useEffect} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {AuthContext} from '../../components/AuthProvider';

import LoginScreen from '../../screens/LoginScreen';
import PrivateScreen from '../../screens/PrivateScreen';
import Config from '../../config/config';

const AppNavigator = () => {
  const {user, i18n} = useContext(AuthContext);

  // console.log('AppNavigator', user);
  const Screen = user ? PrivateScreen : LoginScreen;

  const config = {
    screens: {
      Profile: '',
      Login: 'login',
      // CodeVerifying: 'code_verifying',
      // PasswordVerifying: 'password_verifying',
      // EmailCreating: 'email_creating',
      // ResetPassword: 'reset_password',
      Balance: 'balance',
      Trios: 'trios/:id?',
      Invitations: 'invitations/:id?',
      History: 'history',
      Transactions: 'transactions',
      PrivacyPolicy: 'privacy',
      About: 'about',
      Contacts: 'contacts',
      AdminMessages: 'admin_messages',
      FirstPage: 'welcome',
      ActivityDetails: 'request_details',
      RefOrders: 'referal_orders',
      Cashback: 'cashback'
    },
  };

  const linking = {
    prefixes: [`${Config.API_URL}`],
    config,
  };

  useEffect(() => {
    if (user && user.lang) {
      i18n.changeLanguage(user.lang);
    }
  }, [user, i18n])

  return (
    <NavigationContainer linking={linking}>
      <Screen />
    </NavigationContainer>
  );
};

export default AppNavigator;
