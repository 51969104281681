import React, {useContext} from 'react';
import {
  Text,
  View,
  Image,
  ImageBackground,
  Pressable,
} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../../components/AuthProvider';

import LoginHeaderLogo from '../../assets/img/LoginHeaderLogo.svg';
import GreyBlock from '../../assets/img/grey_block.svg';
import BlueBlock1 from '../../assets/img/blue_block1.svg';
import BlueBlock2 from '../../assets/img/blue_block2.svg';
import Percents from '../../assets/img/50percents.svg';
import Share from '../../assets/img/share.svg';
import Ellips from '../../assets/img/ellips.svg';
import LeftRing1 from '../../assets/img/left_ring_1.svg';
import LeftRing2 from '../../assets/img/left_ring_2_2.svg';
import LeftRing3 from '../../assets/img/left_ring_3.svg';
import RightRing1 from '../../assets/img/right_ring_1.svg';
import RightRing2 from '../../assets/img/right_ring_2.svg';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import {default as commonStylesF} from '../../styles/common';
import {default as firstPageStylesF} from '../../styles/firstPage';


const FirstPage = ({navigation, route}) => {
  const {t, theme, logout} = useContext(AuthContext);

  let commonStyles = commonStylesF(wp, hp, theme);
  let firstPageStyles = firstPageStylesF(wp, hp, theme);

  return (
    <View
      style={commonStyles.container}
    >
      <Header navigation={false} route={false} />
      {/* Форма входа и три прямоугольных блока */}
      <View style={firstPageStyles.firstBlock}>
        {/* Форма входа */}
        <View style={firstPageStyles.loginContainer}>
          <ImageBackground
            style={firstPageStyles.firstPageLogo}
            source={LoginHeaderLogo}>
            <Text style={firstPageStyles.logoTextTrio}>{t('cashback')}</Text>
            <Text style={firstPageStyles.logoTextBottom}>
              {t('partner_program')}
            </Text>
          </ImageBackground>

          <View style={firstPageStyles.btnGroup}>
            <Pressable
              style={firstPageStyles.btnCommon}
              onPress={() => navigation.navigate('Login')}
            >
              <Text style={firstPageStyles.btnCommonTxt}>
                {t('login')}
              </Text>
            </Pressable>
            <Pressable
              style={firstPageStyles.btnCommon}
              onPress={() => navigation.navigate('Login')}
              >
              <Text style={firstPageStyles.btnCommonTxt}>{t('registration')}</Text>
            </Pressable>
          </View>
        </View>
        {/** три прямоугольных блока */}
        <View style={firstPageStyles.blocksContainer}>
          <ImageBackground
            style={firstPageStyles.greyBlock}
            source={GreyBlock}
          >
            <Image
              source={BlueBlock1}
              style={firstPageStyles.blueBlock1}
            />
            <Image
              source={BlueBlock2}
              style={firstPageStyles.blueBlock2}
            />
          </ImageBackground>

        </View>
      </View>
      {/* /Форма входа и три прямоугольных блока */}

      {/** */}
      <View style={firstPageStyles.secondBlock}>
        <View style={firstPageStyles.secondBlockPart1}>
          <Text style={firstPageStyles.secondBlockText}>{t('why')}</Text>
          <Text style={firstPageStyles.secondBlockText}>{t('should')}</Text>
          <Text style={firstPageStyles.secondBlockText}>{t('join')}</Text>

          <Text style={{...firstPageStyles.secondBlockText, color: '#3E3EFF'}}>
            <Text style={{...firstPageStyles.secondBlockText, color: 'black'}}> {t('to')} </Text>
            {t('to_cashback')}
            <Text style={{...firstPageStyles.secondBlockText, color: 'black'}}>?</Text>
          </Text>
        </View>
        <View style={firstPageStyles.secondBlockPart2}>
          <View style={firstPageStyles.blueBox1}>
            <Text style={firstPageStyles.boxHead}>{t('quick_earnings')}</Text>
            <Text style={firstPageStyles.block2TextContent}>
              {t('quick_earnings_text1')}
            </Text>
            <Text style={firstPageStyles.block2TextContent}>
              {t('quick_earnings_text2')}
            </Text>
          </View>
          <View style={firstPageStyles.blackBox1}>
            <Text style={firstPageStyles.boxHead}>{t('no_limits')}</Text>
            <Text style={firstPageStyles.block2TextContent}>
              {t('no_limits_text')}
            </Text>
            {/* <Image
              style={{
                width: 269,
                height: 276,
                marginTop: -55,
                marginLeft: -45
              }}
              source={Percents}
            /> */}
          </View>
          <View style={firstPageStyles.blueBox2}>
            <Text style={firstPageStyles.boxHead}>{t('automatic_registration')}</Text>
            <Text style={firstPageStyles.block2TextContent}>
              {t('automatic_registration_text1')}
            </Text>
            <Text style={firstPageStyles.block2TextContent}>
              {t('automatic_registration_text2')}
            </Text>
          </View>
          <View style={firstPageStyles.blackBox2}>
            <Text style={firstPageStyles.boxHead}>{t('lightness_and_simplicity')}</Text>
            <Text style={firstPageStyles.block2TextContent}>
              {t('lightness_and_simplicity_text')}
            </Text>
            <Image
              style={{
                width: 285,
                height: 307,
                marginTop: -50,
                marginLeft: -25
              }}
              source={Share}
            />
          </View>
        </View>
        <View style={firstPageStyles.secondBlockPart3}>
          <Text style={firstPageStyles.secondBlockText2}>{t('what_is')}</Text>
          <Text style={firstPageStyles.secondBlockText2}>
            «<Text style={{...firstPageStyles.secondBlockText2, color: theme.FONT_COLOR_BLUE}}>{t('cashback')}</Text>»?
          </Text>
          <Text style={firstPageStyles.secondBlockText2}>{t('lets_calc')}</Text>
          <Image
            source={Ellips}
            style={firstPageStyles.ellips}
          />
        </View>
      </View>

      {/** */}
      <View style={firstPageStyles.thirdBlock}>
        <View style={firstPageStyles.thirdBlockTxt1Container}>
          <Text style={firstPageStyles.thirdBlockTxt1}>{t('suggest')} <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_one')}</Text> {t('friend_to_purchase')}</Text>
          <Text style={firstPageStyles.thirdBlockTxt1}>{t('then_again')} <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_one')}</Text>.</Text>
          <Text style={firstPageStyles.thirdBlockTxt1}>{t('and_again')} <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_one')}</Text>.</Text>
        </View>
        <View style={firstPageStyles.thirdBlockPart2}>
          <View style={firstPageStyles.ringsLeft}>
            <Image
              source={LeftRing1}
              style={firstPageStyles.left_ring_1}
            />
            <Image
              source={LeftRing3}
              style={firstPageStyles.left_ring_3}
            />
            <Image
              source={LeftRing2}
              style={firstPageStyles.left_ring_2}
            />
          </View>
          {/* <View style={firstPageStyles.trioArea}>
            <Text style={firstPageStyles.trioAreaTxt}>{t('sum_of_trio')} {t('cashback')}</Text>
          </View> */}
          <View style={firstPageStyles.ringsRight}>
            <Image
              source={RightRing1}
              style={firstPageStyles.right_ring_1}
            />
            <Image
              source={RightRing2}
              style={firstPageStyles.right_ring_2}
            />
          </View>
        </View>
        <View style={firstPageStyles.fouthBlock}>
          <View style={firstPageStyles.fouthBlockTxtContainer}>
            <Text style={firstPageStyles.thirdBlockTxt1}>
            <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('cashback')} </Text>
             =
             <Text style={{color: theme.FONT_COLOR_BLUE}}> {t('100_percents')} </Text>
              {t('from_order_sum')}
            </Text>
          </View>
          <View style={firstPageStyles.fouthBlockPart2}>
            <View style={firstPageStyles.leftRings1}>
            <Image
              source={RightRing1}
              style={firstPageStyles.left_ring_1_1}
            />
            <Image
              source={RightRing2}
              style={firstPageStyles.left_ring_2_1}
            />
            </View>
            <View style={firstPageStyles.loginContainer1}>
              <Text style={firstPageStyles.thirdBlockTxt1}><Text style={{color: theme.FONT_COLOR_BLUE}}>{t('authorize')}</Text>, {t('to_start_earning')}</Text>
              <Text style={firstPageStyles.thirdBlockTxt1}>{t('with_friends')}!</Text>
              <View style={firstPageStyles.btnGroup2}>
                <Pressable
                  style={firstPageStyles.btnCommonBlack}
                  onPress={() => navigation.navigate('Login')}
                >
                  <Text style={firstPageStyles.btnCommonTxt}>
                    {t('login')}
                  </Text>
                </Pressable>
                <Pressable
                  style={firstPageStyles.btnCommonBlack}
                  onPress={() => navigation.navigate('Login')}
                  >
                  <Text style={firstPageStyles.btnCommonTxt}>{t('registration')}</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Footer navigation={navigation}/>
    </View>
  )
}

export default FirstPage;