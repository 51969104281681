import React, {useContext} from 'react';
import {Pressable, Platform} from 'react-native';
import {Text, HStack} from 'native-base';
import {Card, Avatar, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import {useNavigation} from '@react-navigation/native'; // Для використання спеціально на iOS

import {formInitials, nameToFio, replaceWithAsterisks, replaceNameWithAsterics} from '../../components/common/commonFunctions';
import {default as listStylesF} from '../../styles/list';
import {default as triosStylesF} from '../../styles/trios_mobile';

const ReferalHeaderTable = props => {
  const {t, theme} = useContext(AuthContext);
  const triosStyles = triosStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('contact')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const ReferalTable = ({item, navigation}) => {
  const {user, t, theme, setScreenParams} = useContext(AuthContext);
  // const { pushBreadcrumb } = useNavigation();
  const triosStyles = triosStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);
  const name =
    item.invitation_id
    ? nameToFio(item.name)
    : replaceNameWithAsterics(nameToFio(item.name));
  const phone = item.invitation_id ? item.phone : replaceWithAsterisks(item.phone);

  const showRefOrders = item_id => {
    setScreenParams({id: item_id, name: name});
    if (Platform.OS !== 'web') {
      navigation.navigate('RefOrders', {id: item_id});
    } else {
      navigation.push('RefOrders', {id: item_id});
    }
  };
  return (
    <DataTable.Row
      key={item.id}
      style={listStyles.itemRow}
      onPress={() => {
        user.role !== 0
          ? showRefOrders(item.id)
          : console.log('trio ' + item.id);
      }}>
      <DataTable.Cell style={triosStyles.itemCell}>
        {/* {Platform.OS === 'web' && (
          <Image
            source={require('../../assets/img/three_icons.png')}
            style={triosStyles.threeIcon}
          />
        )} */}
        <Text
          style={[
            listStyles.itemCol,
            Platform.OS === 'ios' ? {fontSize: 12} : {},
          ]}>
          {item.created_at.substr(0, 10)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{name}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.invitation_id ? item.phone : replaceWithAsterisks(item.phone)}</Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const ReferalHeaderCard = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);

  const dateSort = direction => {
    props.setSortField('created_at');
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  const nameSort = direction => {
    props.setSortField('name');
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            nameSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('name')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const ReferalCard = ({item, navigation: propNavigation}) => {
  const {t, user, theme, setScreenParams} = useContext(AuthContext);
  const iosNavigation = useNavigation();
  let triosStyles = triosStylesF(wp, hp, theme);
  const name =
    item.invitation_id
    ? nameToFio(item.name)
    : replaceNameWithAsterics(nameToFio(item.name));
  const phone = item.invitation_id ? item.phone : replaceWithAsterisks(item.phone);

  const showRefOrders = item_id => {
    const navigation = Platform.OS === 'ios' ? iosNavigation : propNavigation;
    setScreenParams({id: item_id, name: name});
    if (Platform.OS !== 'web') {
      navigation.navigate('RefOrders', {id: item_id});
    } else {
      navigation.push('RefOrders', {id: item_id});
    }
  };

  const Right = () => (
    <Avatar.Text
      label={formInitials(`${item.name}`)}
      size={55}
      style={{
        borderRadius: 5,
        justifyContent: 'center',
      }}
      labelStyle={{
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 24,
      }}
      color={theme.FONT_COLOR_WHITE}
      theme={{
        colors: {
          primary: theme.LIGHT_BLUE,
        },
      }}
    />
  );

  const title = `${t('name')}: ${name}`;
  const subtitle = `${t('phone')}: ${phone}`;

  return (
    <DataTable.Row
      key={item.id}
      style={{width: wp('90%'), alignItems: 'center'}}>
      <Card
        mode={'outlined'}
        style={triosStyles.cardStyle}
        onPress={() => {
          user.role !== 0
            ? showRefOrders(item.id)
            : console.log('referal ' + item.id);
        }}>
        <Card.Title
          title={title}
          subtitle={subtitle}
          right={Right}
          titleStyle={triosStyles.cardTitleStyle}
          subtitleStyle={triosStyles.cardSubtitleStyle}
        />
        <Card.Content>
          <Text style={triosStyles.cardContentStyle}>
            {t('date')}: {item.created_at.substr(0, 10)}
          </Text>
          <Text style={triosStyles.cardContentStyle}>{item.description}</Text>
        </Card.Content>
      </Card>
    </DataTable.Row>
  );
};

export { ReferalHeaderTable, ReferalHeaderCard, ReferalTable, ReferalCard};
